import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { useBrandingContext } from '../../context/BrandingContext';

const ErrorAlert = (props) => {
  const { supportEmail } = useBrandingContext();
  const {
    color,
    error,
    className,
    hideDuration,
    autoHide,
    isGraphQlError,
    showReferFaq,
  } = props;
  const [showError, setShowError] = useState(!!error);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  useEffect(() => {
    if (showError && autoHide) {
      setTimeout(() => {
        setShowError(false);
      }, hideDuration);
    }
  }, [autoHide, showError, hideDuration]);

  if (showError && error) {
    let errors;
    if (isGraphQlError) {
      errors = error.graphQLErrors.map((err) => err.message);
    } else if (error instanceof Array) {
      errors = error;
    } else {
      errors = [error];
    }

    return (
      <Alert color={color} className={className}>
        Warning:
        {errors.length > 1 ? (
          <ul>
            {errors.map((msg, index) => (
              <>{!!msg && <li key={index}>{msg}</li>}</>
            ))}
          </ul>
        ) : (
          ` ${errors[0]}`
        )}
        {showReferFaq &&
          ` Please refer the FAQs or reach out the customer
          support at ${supportEmail} for assistance.`}
      </Alert>
    );
  }
  return null;
};

ErrorAlert.defaultProps = {
  error: null,
  autoHide: false,
  hideDuration: 5000,
  className: 'mb-3',
  isGraphQlError: true,
  showReferFaq: false,
  color: 'warning',
};

ErrorAlert.propTypes = {
  error: PropTypes.any,
  autoHide: PropTypes.bool,
  hideDuration: PropTypes.number,
  className: PropTypes.string,
  isGraphQlError: PropTypes.bool,
  showReferFaq: PropTypes.bool,
  color: PropTypes.string,
};

export default ErrorAlert;
